<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input placeholder="团购编号" class="search-input" v-model="searchObj.keyword"></el-input>
          <el-input placeholder="商品名称" class="search-input ml-15" v-model="searchObj.keyword2"></el-input>
          <el-select placeholder="选择状态" class="search-input ml-15" v-model="searchObj.status">
            <el-option
              v-for="(item,index) in groupby_status"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="团购编号" prop="orderNo"></el-table-column>
            <el-table-column label="商品条形码" prop="goodsCode"></el-table-column>
            <el-table-column label="商品图片">
              <template slot-scope="scope">
                <el-avatar
                  :src="picsPath(scope.row.goodsImg)"
                  shape="square"
                  alt="加载失败"
                  :key="scope.row.goodsImg"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" prop="goodsName"></el-table-column>
            <el-table-column label="拼团价" prop="activityPrice"></el-table-column>
            <el-table-column label="团长" prop="niken"></el-table-column>
            <el-table-column label="开团时间" prop="createTime">
              <template
                slot-scope="scope"
              >{{scope.row.createTime | dataFormat('yyyy-MM-dd hh:mm:ss')}}</template>
            </el-table-column>
            <el-table-column label="拼团状态">
              <template slot-scope="scope">{{scope.row.status | statusFilter}}</template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="viewOrder(scope.row,scope.row.id)"
                  >
                    <i class="el-icon-info"></i>
                    查看
                  </el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="showOrder" title="团购详情">
      <!-- 基本信息 -->
      <div class="pad-container">
        <el-form class="mt-12" label-width="100px" :model="orderData">
          <el-form-item label="团购编号">
            <template>{{orderData.orderNo}}</template>
          </el-form-item>
          <el-form-item label="团购状态">
            <template>{{orderData.status | statusFilter}}</template>
          </el-form-item>
          <el-form-item label="开团时间">
            <template>{{orderData.beginTime | dataFormat('yyyy-MM-dd hh:mm:ss')}}</template>
          </el-form-item>
          <el-form-item label="结束时间">
            <template>{{orderData.endTime | dataFormat('yyyy-MM-dd hh:mm:ss')}}</template>
          </el-form-item>
        </el-form>
      </div>
      <div class="pad-container mt-12">
        <el-form label-width="100px">
          <el-form-item label="商品信息">
            <template>{{orderData.goodsName}}</template>
          </el-form-item>
        </el-form>
      </div>
      <div class="pad-container mt-12">
        <el-form label-width="100px">
          <el-form-item label="参团信息">
            <template>{{orderData.isVirtual == 1 ?'虚拟成团 ':'非虚拟成团'}}</template>
          </el-form-item>
          <el-form-item label="成团人数">
            <template>{{orderData.groupLimit}}</template>
          </el-form-item>
          <el-form-item label="参团人数">
            <template>{{orderData.userList?orderData.userList.length:0}}</template>
          </el-form-item>
        </el-form>
      </div>
      <div class="pad-container mt-12">
        <el-table class="member-table" :data="userTableData">
          <el-table-column label="用户头像">
            <template slot-scope="scope">
              <el-avatar
                :src="picsPath(scope.row.userInfo.headPortrait)"
                shape="square"
                alt="加载失败"
                :key="scope.$index"
              ></el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="用户昵称">
            <template slot-scope="scope">{{scope.row.userInfo.nickname}}</template>
          </el-table-column>
          <el-table-column label="角色" prop="role">
            <template slot-scope="scope">{{scope.row.role==0?'团长':'团员'}}</template>
          </el-table-column>
          <el-table-column label="是否虚拟" prop="userType">
            <template slot-scope="scope">{{scope.row.userType==0?'是':'否'}}</template>
          </el-table-column>
          <el-table-column label="参团时间" prop="joinedTime">
            <template
              slot-scope="scope"
            >{{scope.row.joinedTime | dataFormat('yyyy-MM-dd hh:mm:ss')}}</template>
          </el-table-column>
          <el-table-column label="订单编号" prop="orderNo"></el-table-column>
        </el-table>
        <!-- <Pagination
          :total="orderTotal"
          :pageNum="orderPageNum"
          :pageSize="orderPageSize"
          :pageSizes="orderPageSizes"
          :hideSizes="true"
          :type="'dialog'"
          @changePage="changePage"
          @syncPageData="orderSyncPageData"
        /> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/utils/request";
import Pagination from "@/components/Pagination";
import { getGroupPersonList, getGroupPersonDetail} from "@/api/maketing/groupbuy"
export default {
  name: "members_list",
  components: {
    Pagination
  },
  data() {
    return {
      // orderTotal: 0,
      // orderPageNum: 1,
      // orderPageSize: 5,
      // orderPageSizes: [5, 20, 30, 50, 100],
      // 顶部搜索栏数据
      searchObj: {
        keyword: "",
        keyword2: "",
        status: ""
      },
      // 拼团状态列表,用于筛选
      groupby_status: [
        { id: 0, name: "拼团中" },
        { id: 2, name: "拼团失败" },
        { id: 3, name: "拼团成功" }
      ],

      // 拼团表格数据内容
      tableData: [],
      userTableData: [],
      allData: [],
      createUser: "",
      addFlag: true,
      classifyId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      showOrder: false,
      orderData: {},
      cou_form: {}
    };
  },
  filters: {
    statusFilter(val) {
      switch (val) {
        case -1:
          return "未生效";
        case 0:
          return "拼团中";
        case 2:
          return "拼团失败";
        case 3:
          return "拼团成功";
        default:
          return "--";
      }
    }
  },
  mounted() {
    this.classifyId = this.$route.query.id;
    this.initData();
    // this.getType();
  },
  methods: {
    changePage() {},
    //获取所有多人拼团
    initData() {
      let params={
        id:this.classifyId,
        size:this.pageSize,
        page:this.pageNum,
        status:this.searchObj.status,
        orderNo:this.searchObj.keyword,
        goodsName:this.searchObj.keyword2.trim()
      }
      getGroupPersonList(params).then(res => {
        // console.log(res.data.body)
        this.total = res.data.body.createds.total;
        // this.allData = [];
        this.orderData.beginTime = res.data.body.beginTime;
        this.orderData.endTime = res.data.body.endTime;
        this.orderData.virtual = res.data.body.virtual;
        this.orderData.groupLimit = res.data.body.groupLimit;
        this.tableData = res.data.body.createds.list;
        // res.data.body.createds.list.map((item, index) => {
        //   if (
        //     (item.status == this.searchObj.status ||
        //       !String(this.searchObj.status)) &&
        //     (item.orderNo == this.searchObj.keyword ||
        //       !this.searchObj.keyword) &&
        //     (this.searchObj.keyword2.includes(item.goodsName) ||
        //       !this.searchObj.keyword2)
        //   ) {
        //     item ? this.allData.push(item) : "";
        //   }
        // });
        
        // console.log(this.allData)
        // this.getTableData();
        // console.log(this.tableData, "this.tableData");
      })
    },
    // 搜索事件
    search() {
      this.pageNum = 1;
      this.initData();
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        keyword: "",
        keyword2: "",
        status: ""
      };
      this.search();
    },
    // 订单用户切换页面
    // orderSyncPageData(data) {
    //   this.orderPageNum = data.num;
    //   this.orderPageSize = data.size;
    //   this.getUserTableData();
    // },
    // 获取订单用户列表
    // getUserTableData() {
    //   this.userTableData = this.orderData.userList.slice(
    //     (this.orderPageNum - 1) * this.orderPageSize,
    //     (this.orderPageNum - 1) * this.orderPageSize + this.orderPageSize
    //   );
    // },
    // 订单列表切换页面
    syncPageData(data) {
      console.log(data)
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.initData();
    },
    // 获取订单列表
    // getTableData() {
    //   // console.log(this.allData)
    //   this.tableData = this.allData.slice(
    //     (this.pageNum - 1) * this.pageSize,
    //     (this.pageNum - 1) * this.pageSize + this.pageSize
    //   );
    // },
    // 查看
    viewOrder(row, id) {
      getGroupPersonDetail(id).then(res => {
        // this.orderTotal = res.data.body.userList.length;
        console.log(res.data.body)
        this.orderData = Object.assign(this.orderData, res.data.body);
        this.orderData.goodsName = row.goodsName;
        this.userTableData = res.data.body.userList;
        // this.getUserTableData();
        // console.log(this.orderData, "this.orderData");
      });
      this.showOrder = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>
